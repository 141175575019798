import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
    public newsletterPath: string = 'contact-address/add'

  // Initialize
  constructor(private http: HttpClient) { }

  // Subscribe to newsletter
  submitNewsletter(email): Observable<any> {
    return this.http.post(`${environment.api_base_url}${this.newsletterPath}`, {email});
  }
  

}
