<div class="img-wrapper" (click)="NewsLetter.openModal()">
	<div class="front">
        <a>
            <img [src]="product?.img"
                 class="img-fluid lazy-loading" alt="">
        </a>
	</div>
</div>
<div class="product-detail mt-4">
    <div>
      <a >
        <h6>{{ product?.name | titlecase }}</h6>
      </a>
      <h4>
        <del [ngStyle]="{'text-decoration': 'none'}" ><span class="money">a reversé {{ product?.gifted | currency:currency?.currency:'symbol' }} aux associations</span></del>
      </h4>
    </div>
  </div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<!-- modals -->
<app-newsletter #newsletterModal></app-newsletter>