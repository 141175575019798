<div class="icon-nav mobile-hidden" (click)="NewsLetter.openModal()">
  <a 
  class="btn btn-solid nav-login-btn">
  Connexion
  </a>

</div>

<!-- TODO : REMOVE THIS ONCE LOGIN IS PLUGGED  -->
<app-newsletter #newsletterModal></app-newsletter>
