<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>Restez informé</h4>
								<p>Soyez informé de la sortie de Pethical. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form"
							action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
							target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="Entrez votre e-mail">
							</div>
							<button type="submit" class="btn btn-solid">S'abonner</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>à propos</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p> Le premier market place écosolidaire dédié aux animaux de compagnie. </p>
						<!-- <div class="footer-social">
                            <ul>
                                <li>
									<a href="https://www.facebook.com/pethical.fr"><i class="fa fa-facebook" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="https://twitter.com/pethical_fr"><i class="fa fa-twitter" aria-hidden="true"></i></a>
								</li>
                            </ul>
                        </div> -->
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Liens utiles</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li *ngFor="let menuItem of menuItems"><a [routerLink]="menuItem.path" routerLinkActive="active"
										[routerLinkActiveOptions]="{exact: true}" *ngIf="menuItem.type === 'link'">{{ menuItem.title }}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Réseaux sociaux</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li><a href="https://www.facebook.com/pethical.fr">Facebook</a></li>
								<li><a href="https://twitter.com/pethical_fr">Twitter</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Contactez-nous</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li><i class="fa fa-map-marker"></i>Paris</li>
								<li><i class="fa fa-phone"></i>Téléphone : <a href="tel:+33603248425">+33 6 03 24 84 25</a></li>
								<li><i class="fa fa-envelope-o"></i>Email : <a href="mailto:contact@pethical.fr">contact@pethical.fr</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Pethical.fr. Tous droits
							réservés</p>
					</div>
				</div>
				<!-- <div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->