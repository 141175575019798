<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content"><img src="assets/images/pethical/header_logo.png"
                                    class="img-fluid mt-auto" alt="">
                                <h2>{{isEmailStored ? 'Merci !' : 'Bientôt disponible !'}}</h2>
                                <p class="text-center">Pethical est en cours de développement, inscrivez-vous à la
                                    newsletter pour ne rien manquer.</p>
                                <form *ngIf="!isEmailStored" class="auth-form needs-validation" method="post"
                                    id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                                    <div *ngIf="hasError">
                                        <p class="text-center error">Une erreur est survenue lors de l'enregistrement
                                            de votre email, veuillez réessayer</p>
                                    </div>
                                    <div class="form-group mx-sm-3">
                                        <input [(ngModel)]="email" type="text" class="form-control" name="EMAIL"
                                            id="mce-EMAIL" placeholder="Adresse e-mail" required="required">
                                        <button (click)="submitNewsletter()" type="submit" class="btn btn-solid"
                                            id="mc-submit">S'abonner</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->